<template>
  <div class="warning-rule-search-container">
    <span class="shaixuan">筛选</span>
    <a-input class="search_item" placeholder="请输入预警名称" v-model.trim="searchquery.name" @input="changeName" />
    <m-select-one
      v-if="role === 'SUPER_ADMIN'"
      class="search_item"
      :popoverWidth="'240px'"
      label="账户"
      :showId="false"
      :hasOs="false"
      :hasPosition="false"
      :allData="adminList"
      v-model="searchquery.company"
      :allowClear="true"
      @change="handleAutoSearch"
    />
    <a-select
      allowClear
      v-model="searchquery.target"
      placeholder="请选择预警对象"
      class="search_item"
      @change="handleAutoSearch"
    >
      <a-select-option :value="1"> 应用 </a-select-option>
      <a-select-option :value="2"> 广告位 </a-select-option>
    </a-select>
    <a-select
      allowClear
      v-model="searchquery.sts"
      placeholder="请选择预警状态"
      class="search_item"
      @change="handleAutoSearch"
    >
      <a-select-option value="A"> 开启 </a-select-option>
      <a-select-option value="S"> 关闭 </a-select-option>
    </a-select>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { mapState } from 'vuex'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        company: undefined,
        sts: undefined,
        target: undefined,
        name: undefined
      },
      timer: null
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  mounted () {
    this.$emit('handleAutoSearch', this.searchquery)
    this.getAdminList()
  },
  methods: {
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    },
    // 防抖搜索
    changeName () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.warning-rule-search-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 10px;
  .shaixuan {
    width: 70px;
    color: #333;
    font-weight: 500;
  }
  .search_item {
    width: 210px;
    margin: 10px 10px 10px 0;
  }
}
</style>
